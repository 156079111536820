<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('trainings.types.new')">
        <actions
          slot="actions"
          crud-links="trainingTypes"
          controller="TrainingTypes"
          :actions="actions"
        />
        <data-form
          :training-type="trainingType"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'training-types-new',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      headers: { 'Content-Type': 'multipart/form-data' },
      trainingType: {},
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'trainings/types/'
    },
    async submit (trainingType) {
      this.loading = true

      try {
        await this.$http.post(this.routeBuilder(), trainingType, { headers: this.headers })
      } catch (e) {
        // console.log('Error while creating training type', e)
        this.loading = false
        return
      }

      this.$router.push({ name: 'trainingTypesIndex' })
    },
  },
}
</script>
